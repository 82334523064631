<template>
  <div>
    <div v-if="watingCode">
      <div>
        <label for="password">{{ $t('user.password_new') }}: </label>
        <input type="password" id="password" v-model="password">
      </div>
      <div>
        <label for="password-confirmation">{{ $t('user.password_confirmation') }}</label>
        <input type="password" id="password-confirmation" v-model="passwordConfirmation">
      </div>
      <div>
        <label for="code">{{ $t('default.code') }}: </label>
        <input type="code" id="code" v-model="code">
      </div>
      <button @click="setNewPassword">确定</button>
    </div>
    <div v-else>
      <div>
        <label for="email">{{ $t('user.email') }}: </label>
        <input type="email" id="email" v-model="email">
      </div>
      <button @click="sendValidateCode">发送验证码</button>
      <button @click="skipSend">已有验证码</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ForgetPassword',
  data: () => ({
    resendTime: 0,
    email: '',

    watingCode: false,
    code: '',
    password: '',
    passwordConfirmation: '',
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  created() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
    }
    if (this.$route.query.code && this.email) {
      this.watingCode = true;
      this.code = this.$route.query.code;
    }
    if (this.user.token !== null) {
      this.returnLastPath();
    }
  },
  methods: {
    // 跳过发送验证码
    skipSend() {
      if (!this.email) {
        this.$tip.fire('请先输入邮箱，再点击跳过');
        return;
      }
      this.watingCode = true;
    },
    // 发送验证码
    sendValidateCode() {
      if (!this.email) {
        return this.$tip.fire('请先输入邮箱');
      }
      const params = {
        email: this.email,
      };
      // 发送
      return this.$http.post('api/forgot/pass-code', params)
        .then(() => {
          this.watingCode = true;
        })
        .catch((error) => {
          this.$tip.fire(error.response.data.message);
        });
    },
    // 校验 code 并输入新密码
    setNewPassword() {
      if (!this.email) {
        return this.$tip.fire('请先输入邮箱');
      }
      if (!this.code) {
        return this.$tip.fire('先输入验证码，才能更改密码哟');
      }
      const params = {
        email: this.email,
        code: this.code,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
      };
      // 发送
      return this.$http.post('api/forgot/pass-new', params)
        .then(() => {
          this.$tip.fire({
            title: this.$t('default.succeed'),
            text: this.$t('user.reset_password_done'),
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Yes',
          }).then(() => {
            this.$router.push('/sign');
          });
        })
        .catch((error) => {
          this.$tip.fire(error.response.data.message);
        });
    },
    // 返回上层
    returnLastPath() {
      const lastURI = localStorage.getItem('lastURI');
      if (lastURI) {
        localStorage.removeItem('lastURI');
        this.$router.push(lastURI);
      } else {
        this.$router.push('/');
      }
    },
  },
};
</script>

<style scoped>
</style>
